/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                const swiper = new Swiper('.swiper', {
                    loop: true,
                    effect: 'fade',

                    // Pagination pagination
                    pagination: {
                        el: '.swiper-pagination',
                    },

                    // Navigation arrows
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },

                });
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

    $('.main-nav ul li').hover(function () {
        $(this).find('ul:first').css({ visibility: "visible", display: "none" }).slideDown(400);
    }, function () {
        $(this).find('ul:first').css({ visibility: "hidden", display: "none" });
    });

    $(window).scroll(function () {
        if ($(this).scrollTop() > 400) {
            $('header.banner').addClass('small tiny');

            if ($('.cta-banner').length) {
              $('.cta-banner').addClass('short');
            }

        } else {
            $('header.banner').removeClass('small tiny');

            if ($('.cta-banner').length) {
              $('.cta-banner').removeClass('short');
            }
        }
    });

    $("div#mobile-menu").append($("div.main-nav").html());
    var
        menuBtn = $("#menu");
    //overlay = $(".overlay-screen");
    mmenu = $("nav.mobile-menu");

    $(menuBtn).on("click", function (e) {
        $(this).toggleClass("open");
        $("html").toggleClass("no-scroll");
        $("body").toggleClass("no-scroll");
        //$(overlay).toggleClass("is-visible");
        $(mmenu).toggleClass("is-visible");
        e.preventDefault();
    });

    $("#mobile-menu > ul > li:has('ul') > a").before('<div class="menu-open"></div>');
    $("#mobile-menu > ul > li ul li:has('ul') > a").before('<div class="menu-open-inner"></div>');

    $(".menu-open").click(function () {
        $(this).parent().children('ul.sub-menu').slideToggle();
        $('.menu-open').not(this).parent().children('ul.sub-menu').slideUp();
        $(this).toggleClass('more');
        $('.menu-open').not(this).removeClass('more');
    });

    $(".menu-open-inner").click(function () {
        $(this).parent().children('ul.sub-menu').slideToggle();
        $(this).toggleClass('more');
    });

})(jQuery); // Fully reference jQuery after this point.
